import React from "react";
import { Link, graphql } from "gatsby";
import Img from 'gatsby-image';
import { MDXRenderer } from "gatsby-plugin-mdx";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Breadcrumbs from '../components/Breadcrumbs/Breadcrumbs';
import {
  FacebookShareButton,
  TelegramShareButton,
  ViberShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TelegramIcon,
  ViberIcon,
  WhatsappIcon,
} from "react-share";

class BlogPostTemplate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobile: false
    }
  }

  componentDidMount() {
    this.setState({
      isMobile: window.innerWidth > 767 ? false : true
    });
  }

  render() {
    const post = this.props.data.mdx;
    const siteTitle = this.props.data.site.siteMetadata.title;
    const { previous, next } = this.props.pageContext;
    const {edges: posts} = this.props.data.allMdx;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.summary}
        />
        <div className="view-container view-post">
          <main className="view-post__content">
            <Breadcrumbs 
              items={[
                {
                  title: 'Головна',
                  url: '/'
                }, {
                  title: 'Новини',
                  url: '/posts'
                }, {
                  title: post.frontmatter.title,
                }
            ]}
            />
            <h1 className="view-post__title view-title view-title--h2">{post.frontmatter.title}</h1>
            <div className="view-post__header">
              <time className="view-post__date">{post.frontmatter.date}</time>
            </div>
            <div className="view-text">
              <MDXRenderer>{post.body}</MDXRenderer>
            </div>
            <div className="view-post__share">
              <div className="view-post__share__title">Поділитися статею:</div>
                <FacebookShareButton
                  url={this.props.location.href}
                  quote={post.frontmatter.title}
                >
                  <FacebookIcon round />
                </FacebookShareButton>
                <TelegramShareButton
                  url={this.props.location.href}
                  title={post.frontmatter.title}
                >
                  <TelegramIcon round />
                </TelegramShareButton>
                <ViberShareButton
                  url={this.props.location.href}
                  title={post.frontmatter.title}
                >
                  <ViberIcon round />
                </ViberShareButton>
                <WhatsappShareButton
                  url={this.props.location.href}
                  title={post.frontmatter.title}
                >
                  <WhatsappIcon round />
                </WhatsappShareButton>
            </div>
            <div className="view-post__prev-next">
              {previous && (
                <Link
                  to={previous.fields.slug}
                  rel="prev"
                >
                  <svg width="32" height="10" viewBox="0 0 32 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.1657 0.793938L6.52983 2.15813L4.31804 4.36993L31.25 4.36994L31.25 6.29922L4.31804 6.29921L6.52983 8.51107L5.1657 9.87527L0.624999 5.33457L5.1657 0.793938Z" fill="#222222"/>
                  </svg>
                  {!this.state.isMobile ? previous.frontmatter.title : 'Назад'}
                </Link>
              )}
              {next && (
                <Link
                  to={next.fields.slug}
                  rel="next"
                >
                  {!this.state.isMobile ? next.frontmatter.title : 'Вперед'}
                  <svg width="32" height="10" viewBox="0 0 32 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M26.8343 0.793938L25.4702 2.15813L27.682 4.36993L0.750001 4.36994L0.750001 6.29922L27.682 6.29921L25.4702 8.51107L26.8343 9.87527L31.375 5.33457L26.8343 0.793938Z" fill="#222222"/>
                  </svg>
                </Link>
              )}
            </div>
          </main>
          <aside className="view-post__aside">
            <div className="view-blog-list">
              {posts.map(({ node: post }) => (
                <div key={post.id} className="view-blog-list__item">
                  <Link to={post.fields.slug} className="view-blog-list__item__image">
                    <Img 
                      fluid={post.frontmatter.thumbnail.childImageSharp.fluid}
                      alt="#"/>
                  </Link>
                  <time className="view-blog-list__item__date">
                    {post.frontmatter.date}
                  </time>
                  <Link to={post.fields.slug} className="view-blog-list__item__title">
                    {post.frontmatter.title}
                  </Link>
                  <div className="view-blog-list__item__summary">
                    {post.frontmatter.summary}
                  </div>
                </div>
              ))}
            </div>
          </aside>
        </div>
      </Layout>
    );
  }
}

export default BlogPostTemplate;

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      frontmatter {
        title
        summary
        date(formatString: "YYYY-MM-DD")
      }
      body
    }
    allMdx(limit: 3, sort: {fields: frontmatter___date, order: DESC}) {
      edges {
        node {
          id
          frontmatter {
            title
            summary
            date(formatString: "DD-MM-YYYY")
            thumbnail {
              childImageSharp {
                fluid {
                  # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
